import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import axios from 'axios';

const LAST_UPDATE_URL = `${process.env.REACT_APP_API_BASE_URL}/lastupdated`;

const useStyles = makeStyles(theme => ({
  statusUpdate: {
    '& .MuiTypography-overline': {
      lineHeight: 1,
    }
  },
  label: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: 7,
  },
  container: {
    minHeight: 12,
    marginTop: -4,
  },
  status: {
    color: 'rgba(255, 255, 255, 0.75)',
    fontSize: '0.7rem',
  }
}));

export default function StatusUpdate() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [tile, setTile] = useState(null);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const tileAndDataPromise = axios.get(LAST_UPDATE_URL, { cancelToken: source.token });

    Promise.all([tileAndDataPromise])
      .then(responses => {
        const [tileAndDataRes] = responses;
        tileAndDataRes && tileAndDataRes.data && tileAndDataRes.data.data_fresh && setData(tileAndDataRes.data.data_fresh);
        tileAndDataRes && tileAndDataRes.data && tileAndDataRes.data.tile_fresh && setTile(tileAndDataRes.data.tile_fresh);
      })
      .catch(function(err) {
        return;
      });

    return () => {
      source.cancel('status update api call canceled');
    }
  }, []);

  return (
    <Grid container className={classes.statusUpdate} justifyContent="center">
      <Grid item xs={4} style={{textAlign: 'center'}}>
        <Typography component="span" variant="overline" className={classes.label} >
          Data
        </Typography>
        <Fade in={!!data}>
          <div className={classes.container}>
          {
            data && 
            <Typography component="div" variant="overline" id="status-data-fresh" className={classes.status} >
              {data}
            </Typography>
          }
          </div>
        </Fade>
      </Grid>
      <Grid item xs={4} style={{textAlign: 'center'}}>
        <Typography component="span" variant="overline" className={classes.label} >
          Tile
        </Typography>
        <Fade in={!!tile}>
          <div className={classes.container}>
            {
              tile &&
              <Typography component="div" variant="overline" id="status-tile-fresh" className={classes.status}>
                {tile}
              </Typography>
            }
          </div>
        </Fade>
      </Grid>
    </Grid>
  );
}