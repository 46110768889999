import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LayersIcon from '@material-ui/icons/Layers';
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import ExploreIcon from '@material-ui/icons/Explore';
import InfoIcon from '@material-ui/icons/Info';
import GridOnIcon from '@material-ui/icons/GridOn';
import Tooltip from '@material-ui/core/Tooltip';
import { actionMenuToggled, actionMenuSearchTypeToggled } from './AppBarRedux';
import { useDispatch, useSelector } from "react-redux";
import MoreMenu from './MenuMore';
import UpdateStatus from './MenuStatus';
import { actionSearchVisibleToggled } from '../Search';
import { actionAppToggleComponent } from '../App';
import { isTouchDevice } from '../Map';
import { actionLayerCompactToggled } from '../Layer';
import { actionPrintExpandToggled } from '../Print';
import { actionSheetExpandToggled } from '../Spreadsheet';
import { actionDrawExpandToggled } from '../Draw';
import { actionCustomLayerListToggled } from '../CustomLayer';

const MGIS_DOC_LINK = 'https://docs.google.com/document/d/1e9JAh4edWLZx4Mko7GQVxnRwQ0q2a3a6xXsS5J7SDHY';

const useStyles = makeStyles(theme => ({
  root: {
    width: '20vw',
    height: '100%',
    minWidth: 260,
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      width: '70vw',    
    },
    display: 'flex',
    flexDirection: 'column',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  subsection: {
    backgroundColor: 'whitesmoke'
  },
  searchBtn: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
  },
  searchItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  expandBtn: {
    padding: 10,
  },
  topBtn: {
    padding: 0,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-label': {
      fontSize: '1.15rem',
      color: '#757575',
    }
  },
  help: {
    marginTop: 'auto',
  }
}));

function MenuList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandSearch, setExpandSearch] = useState(true);
  // const [expandMore, setExpandMore] = useState(false);

  const mgisBtnClickHandler = () => {
    window.open('/home/index.html', '_blank');
  }

  const handleExpandSearchClick = (event) => {
    setExpandSearch(!expandSearch)
    event.stopPropagation();
  }

  const handleSearchClicked = (menuType) => () => {
    dispatch(actionMenuSearchTypeToggled(menuType));
    dispatch(actionMenuToggled());
  }

  const openMGISDocs = () => {
    window.open(MGIS_DOC_LINK, '_blank');
  }

  // const handleExpandMoreClick = () => {
  //   setExpandMore(!expandMore);
  // };

  const handleClick = (action) => () => {
    if (action) {
      dispatch(action());
    }
    dispatch(actionMenuToggled());
  }

  const getToggleAction = (component) => {
    const actions = {
      layersVisible: actionLayerCompactToggled,
      printVisible: actionPrintExpandToggled,
      spreadsheetVisible: actionSheetExpandToggled,
      drawVisible: actionDrawExpandToggled,
    };
  
    return actions[component];
  }

  const handleOpenClick = (component) => () => {
    dispatch(actionAppToggleComponent({
      component,
      visible: true,
    }));
    const actionComponentExpanded = getToggleAction(component);
    dispatch(actionComponentExpanded(true));
    dispatch(actionMenuToggled());
  }

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem className={classes.topBtn}>
          <Tooltip title="Home Page">
            <Button className={classes.mgisBtn} onClick={mgisBtnClickHandler}>
              MGIS
            </Button>
          </Tooltip>
          <MoreMenu />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={handleOpenClick('layersVisible')}
        >
          <Tooltip title="Data Overlay">
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Layers" />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={handleClick(actionCustomLayerListToggled)}
          className={classes.subsection}
        >
          <Tooltip title="User Defined Data Overlay">        
            <ListItemText primary="Additional Layer" />
          </Tooltip>
        </ListItem>
        <Divider />
        <ListItem 
          button
          onClick={handleClick(actionSearchVisibleToggled)}
          className={classes.searchBtn}
        >
          <Tooltip title="Search">
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Search" />
          <IconButton onClick={handleExpandSearchClick} className={classes.expandBtn} >
            {expandSearch ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItem>
        <Collapse in={expandSearch} timeout="auto" style={{ minHeight: "unset" }} >
          <Divider />
          <ListItem button onClick={handleSearchClicked('building')} className={classes.searchItem} >
            <ListItemText primary="Buildings" />
          </ListItem>
          <ListItem button onClick={handleSearchClicked('room')} className={classes.searchItem} >
            <ListItemText primary="Rooms" />
          </ListItem>
          <ListItem button onClick={handleSearchClicked('person')} className={classes.searchItem} >
            <ListItemText primary="People" />
          </ListItem>
          <ListItem button onClick={handleSearchClicked('department')} className={classes.searchItem} >
            <ListItemText primary="Departments" />
          </ListItem>
          <ListItem button onClick={handleSearchClicked('roomtype')} className={classes.searchItem} >
            <ListItemText primary="Room Types" />
          </ListItem>
          <Divider />
        </Collapse>
        <ListItem button onClick={handleOpenClick('printVisible')}>
          <Tooltip title="Print PDF Maps">
            <ListItemIcon>
              <PrintIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Print" />
        </ListItem>
        <ListItem
          button
          onClick={handleOpenClick('spreadsheetVisible')}
        >
          <Tooltip title="Custom Data Import/Export">
            <ListItemIcon>
              <GridOnIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Spreadsheet" />
        </ListItem>
        { 
          !isTouchDevice() && 
          <ListItem button onClick={handleOpenClick('drawVisible')} >
            <Tooltip title="Annotation Tools">
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Draw/Measure" />
          </ListItem>
        }
        {/* <ListItem button onClick={handleExpandMoreClick}>
          <IconButton>
            {expandMore ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <ListItemText primary="More" />
        </ListItem>
        <Collapse in={expandMore} timeout="auto">
          <ListItem
            button
            onClick={handleOpenClick('legendVisible')}
          >
            <ListItemIcon>
              <ExploreIcon />
            </ListItemIcon>
            <ListItemText primary="Legend" />
          </ListItem>
          <Divider />
        </Collapse> */}
        <ListItem
          button
          onClick={openMGISDocs}
          className={classes.help}
        >
          <Tooltip title="MGIS Documentation">
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Help" />
        </ListItem>
        <UpdateStatus />
      </List>

    </>
  );
}

export default function Menu() {
  const open = useSelector(state=>state.appBarReducer.menuVisible);
  const dispatch = useDispatch();

  const toggleDrawer = event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    dispatch(actionMenuToggled());
  };

  return (
    <SwipeableDrawer
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      {MenuList()}
    </SwipeableDrawer>
  );
}