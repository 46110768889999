import { createAction, handleActions } from 'redux-actions';
import { sortBy } from 'lodash';
import { searchQuery } from './searchApi';

export const initialState = {
  isVisible: false,
  expandMode: 'fullscreen', // fullscreen, half, compact
  filterBy: '', // none, buildings, rooms, people, departments, room types
  defaultData: {}, // {filterBy: data, ... }
  queryStatus: {}, // {fiels: status (pending, success, failure)}
  buildings: {},
  searchInput: '',
  filterSearchInput: '',
  searchInputIsActive: false, // track when searchInput/supersearch is active
  filterResult: [],
  displayDataCard: false, // flag to push result to data card
  isNewSearch: true, // flag to edit existing result or make a new search
  // result from filter/search
  filters: [],
  roomResult: [],
  roomResultId: '',
  roomResultName: '',
};

export const actionSearchVisibleToggled = createAction('SEARCH_VISIBLE_TOGGLED');
export const actionSearchExpandToggled = createAction('SEARCH_EXPAND_MODE_CHANGED');
export const actionSearchFilterChanged = createAction('SEARCH_FILTER_CHANGED');
export const actionSearchBuildingAPIPopulated = createAction('SEARCH_BUILDING_API_POPULATED');
export const actionSearchQueryStatus = createAction('SEARCH_QUERY_STATUS');
export const actionSearchDefaultDataPopulated = createAction('SEARCH_DEFAULT_DATA_POPULATED');
export const actionSearchGeneralSearchActiveToggled = createAction('SEARCH_GENERAL_SEARCH_ACTIVE_TOGGLED');
export const actionSearchGeneralSearchInputUpdated = createAction('SEARCH_GENERAL_SEARCH_INPUT_UPDATED');
export const actionSearchFilterSearchInputUpdated = createAction('SEARCH_FILTER_SEARCH_INPUT_UPDATED');
export const actionSearchFilterResultUpdated = createAction('SEARCH_FILTER_RESULT_UPDATED');
// filters
export const actionSearchFiltersUpdated = createAction('SEARCH_FILTERS_UPDATED');
export const actionSearchFiltersAdded = createAction('SEARCH_FILTERS_ADDED');
export const actionSearchFiltersRemoved = createAction('SEARCH_FILTERS_REMOVED');
// room result
export const actionSearchRoomResultUpdated = createAction('SEARCH_ROOM_RESULT_UPDATED');
export const actionSearchRoomResultIdCreated = createAction('SEARCH_ROOM_RESULT_ID_CREATED');
export const actionSearchRoomResultNameUpdated = createAction('SEARCH_ROOM_RESULT_NAME_UPDATED');
// data to card
export const actionSearchDisplayDataCard = createAction('SEARCH_DISPLAY_DATA_CARD');

export const actionSearchUpdateFilterResult = () => {
  return (dispatch, getState) => {
    const state = getState().searchReducer;
    const {
      searchInput,
      filterSearchInput,
      filterBy,
      // searchInputIsActive,
      defaultData,
    } = state;

    // searches empty
    if (!searchInput && !filterSearchInput && !filterBy) {
      dispatch(actionSearchFilterResultUpdated([]));
      return;
    }

    if (!searchInput && !filterSearchInput && filterBy) {
      dispatch(actionSearchFilterResultUpdated(defaultData[filterBy]));
      return;
    }
  }
}

export const actionSearchCreateRoomResultId = () => {
  return dispatch => {
    const roomResultId = createId();
    dispatch(actionSearchRoomResultIdCreated(roomResultId));
  }
}

export const actionSearchRooms = (filters, cb, errorHandler) => {
  return async (_, getState) => {
    const idToken = getState().authReducer.idToken;
    const rooms = await queryRoomByFilters(filters, idToken);

    if (!!rooms.error && !!errorHandler) {
      errorHandler(rooms.error)
      if (cb) {cb([])}
    } else if (rooms && !rooms.error && cb) {
      cb(rooms);
    }
  }
}

export const actionSearchUpdateRoomResult = (filters) => {
  return async (dispatch, getState) => {
    const idToken = getState().authReducer.idToken;
    filters = Array.isArray(filters) ? filters : [filters];
    dispatch(actionSearchCreateRoomResultId());
    dispatch(actionSearchRoomResultNameUpdated('Search'));
    dispatch(actionSearchFiltersUpdated(filters));
    const rooms = await queryRoomByFilters(filters, idToken);
    dispatch(actionSearchRoomResultUpdated(rooms));
  }
}

export const actionChangeFilter = (filterType) => {
  return (dispatch, getState) => {
    const { filterBy } = getState().searchReducer;
    if (filterType === filterBy) {
      dispatch(actionSearchFilterChanged(''));
    } else {
      dispatch(actionSearchFilterChanged(filterType));
    }
    dispatch(actionSearchUpdateFilterResult());
  }
}

export const actionSearchUpdateSearchInput = (input) => {
  return (dispatch) => {
    dispatch(actionSearchGeneralSearchInputUpdated(input));
    dispatch(actionSearchGeneralSearchActiveToggled(true));
  }
}

export const actionSearchUpdateFilterSearchInput = (input) => {
  return (dispatch) => {
    dispatch(actionSearchFilterSearchInputUpdated(input));
    dispatch(actionSearchGeneralSearchActiveToggled(false));
  }
}

export const actionSearchPopulateDefaultData = () => {
  return async (dispatch, getState) => {
    const idToken = getState().authReducer.idToken;
    await queryDefaultBuildings(dispatch, idToken);
    dispatch(actionChangeFilter('buildings')); // default filter
    queryDefaultRooms(dispatch, idToken);
    queryDefaultPeople(dispatch, idToken);
    queryDefaultDepartments(dispatch, idToken);
    queryDefaultRoomTypes(dispatch, idToken);
  }
}

// reducer
export const searchReducer = handleActions({
  [actionSearchVisibleToggled]: (state) => {
    return {...state, isVisible: !state.isVisible };
  },
  [actionSearchExpandToggled]: (state) => {
    // return {...state, isExpanded: !state.isExpanded };
  },
  [actionSearchQueryStatus]: (state, action) => {
    const { filterBy, status } = action.payload;
    return {
      ...state,
      queryStatus: {
        ...state.queryStatus,
        [filterBy]: status,
      }
    };
  },
  [actionSearchDefaultDataPopulated]: (state, action) => {
    const { filterBy, data } = action.payload;
    return {
      ...state,
      defaultData: {
        ...state.defaultData,
        [filterBy]: data,
      }
    };
  },
  [actionSearchFilterChanged]: (state, action) => {
    return {...state, filterBy: action.payload };
  },
  [actionSearchGeneralSearchActiveToggled]: (state, action) => {
    return {...state, searchInputIsActive: action.payload };
  },
  [actionSearchGeneralSearchInputUpdated]: (state, action) => {
    return {...state, searchInput: action.payload };
  },
  [actionSearchFilterSearchInputUpdated]: (state, action) => {
    return {...state, filterSearchInput: action.payload };
  },
  [actionSearchFilterResultUpdated]: (state, action) => {
    return {...state, filterResult: action.payload };
  },
  [actionSearchRoomResultUpdated]: (state, action) => {
    return {...state, roomResult: action.payload };
  },
  [actionSearchFiltersUpdated]: (state, action) => {
    return {...state, filters: action.payload };
  },
  [actionSearchFiltersAdded]: (state, action) => {
    return {...state, filters: [...state.filters, action.payload] };
  },
  [actionSearchFiltersRemoved]: (state, action) => {
    const filters = state.filters.filter(filter=>filter.id !== action.payload.id);
    return {...state, filters };
  },
  [actionSearchDisplayDataCard]: (state, action) => {
    return {...state, displayDataCard: action.payload };
  },
  [actionSearchRoomResultIdCreated]: (state, action) => {
    return {...state, roomResultId: action.payload };
  },
  [actionSearchRoomResultNameUpdated]: (state, action) => {
    return{...state, roomResultName: action.payload };
  },
  [actionSearchBuildingAPIPopulated]: (state, action) => {
    const buildings = action.payload && action.payload.reduce((bldgs, building) => {
      bldgs[building.bldrecnbr] = building;
      return bldgs;
    }, {});
    return {...state, buildings };
  },
}, initialState);

// utils
async function queryDefaultData(datatype, queryParam, idToken, formatter, dispatch) {
  const filterBy = datatype;
  dispatch(actionSearchQueryStatus({filterBy, status: 'pending'}));
  const response = await searchQuery(queryParam, idToken, formatter);
  const { data, error, details } = response;
  if (error) {
    dispatch(actionSearchQueryStatus({filterBy, status: 'failure'}));
    console.error('Error query url', details.url, error.message)
    return null;
  } else {
    dispatch(actionSearchQueryStatus({filterBy, status: 'success'}));
    dispatch(actionSearchDefaultDataPopulated({filterBy, data}));
    return data;
  }
}

export async function queryRoomByFilters(filters, idToken) {
  filters = Array.isArray(filters) ? filters : [filters]; 
  const queryParam = filters.reduce((queryStr, filter) => {
    if (filter.type === 'building') {
      queryStr += '&building=' + filter.bldrecnbr;
    } else if (filter.type === 'room') {
      if (filter.rmrecnbr) {queryStr += '&rmrecnbr=' + filter.rmrecnbr}
      if (filter.rmnbr) {queryStr += '&rmnbr=' + filter.rmnbr}
    } else if (filter.type === 'department') {
      queryStr += '&deptall=' + filter.deptid;
    } else if (filter.type === 'occdepartment') {
      queryStr += '&deptocc=' + filter.deptocc;
    } else if (filter.type === 'person') {
      queryStr += '&uniqname=' + filter.person_uniqname;
    } else if (filter.type === 'roomtype') {
      queryStr += '&rmtyp=' + filter.rmtyp;
    } else if (filter.type === 'roomsubtype') {
      queryStr += '&rmtyp=' + filter.rmtyp;
      queryStr += '&rmsubtyp=' + filter.rmsubtyp;
    }

    return queryStr;
  }, '/rooms?');

  const response = await searchQuery(queryParam, idToken);

  if (response.error) {
    return {error: response.error};
  } else {
    return response.data;
  }
}

export function buildingDataFormatter(data) {
  const formatted = data.reduce((formattedBuilding, building) => {
    formattedBuilding.push({
      ...building,
      type: 'building',
      id: 'building-' + building.bldrecnbr,
    });
    return formattedBuilding;
  }, []);
  return sortBy(formatted, 'bld_descrshort');
}

export function roomDataFormatter(data) {
  return data.reduce((acummulator, room) => {
    acummulator.push({
      ...room,
      type: 'room',
      id: 'room-' + room.rmrecnbr,
    });
    return acummulator;
  }, []);
}

function formatDefaultRoomData(data) {
  const formatted = roomDataFormatter(data.rmnbr);
  return formatted.slice(16,25);
}

export function peopleDataFormatter(data) {
  return data.reduce((formatData, person) => {
    if (!person.person_uniqname) {
      return formatData;
    }

    formatData.push({
      ...person,
      type: 'person',
      id: 'person-' + person.person_uniqname.toLowerCase(),
    });
    return formatData;
  }, []);
}

function formatDefaultPeopleData(data) {
  const formatted = peopleDataFormatter(data.person_name);
  return formatted.slice(119,127);
}

export function formatDefaultDepartmentData(data) {
  return data.map(department => {
    return {
      ...department,
      type: 'department',
      id: 'department-' + department.deptid,
    };
  });
}

function formatDefaultRoomTypeData(data) {
  const formatted = data.reduce((newData, roomtype) => {
    // exclude "Office (Research) - ROF"/"Office (Other) - OOF"/"Biological Sciences Research Space - RBS"
    if (!isNumeric(roomtype.rmtyp)) { return newData }

    const newRoomtype = {
      ...roomtype,
      type: 'roomtype',
      id: 'roomtype-' + roomtype.rmtyp,
      label: roomtype.rmtyp_descrshort,
      labelId: roomtype.rmtyp,
      labelContext: roomtype.rmtyp_descr50
    };
    
    newData.push(newRoomtype);      
    
    if (newRoomtype.subtypes && newRoomtype.subtypes.length) {
      const subtypes = newRoomtype.subtypes.map(subtype => {
        return {
          ...subtype,
          type: 'roomsubtype',
          rmtypSubtype: roomtype.rmtyp_descr50 + '-' + subtype.rmsubtyp_descr50,
          roomtype: roomtype.rmtyp,
          id: 'roomsubtype-' + roomtype.rmtyp + '-' + subtype.rmsubtyp,
          label: roomtype.rmtyp_descrshort + '-' + subtype.rmsubtyp_descrshort,
          labelId: roomtype.rmtyp + '-' + subtype.rmsubtyp,
          labelContext: roomtype.rmtyp_descr50 + '-' + subtype.rmsubtyp_descr50,
          isSubtype: 'true',
        }
      });
      newData.push(...sortBy(subtypes, 'rmsubtyp'))
    }
    return newData;
  }, []);

  return formatted;
}

async function queryDefaultBuildings(dispatch, idToken) {
  const buildingsFirstAttempt = await queryDefaultData(
    'buildings',
    '/buildings',
    idToken,
    buildingDataFormatter,
    dispatch
  );

  if (buildingsFirstAttempt) {
    dispatch(actionSearchBuildingAPIPopulated(buildingsFirstAttempt));
    return buildingsFirstAttempt;   
  }

  const buildingsSecondAttempt = await queryDefaultData(
    'buildings',
    '/buildings',
    idToken,
    buildingDataFormatter,
    dispatch
  );

  dispatch(actionSearchBuildingAPIPopulated(buildingsSecondAttempt));
  return buildingsSecondAttempt;
}

async function queryDefaultRooms(dispatch, idToken) {
  const rooms = await queryDefaultData(
    'rooms',
    '/supersearch/123',
    idToken,
    formatDefaultRoomData,
    dispatch
  );
  return rooms;
}

async function queryDefaultPeople(dispatch, idToken) {
  const people = await queryDefaultData(
    'people',
    '/supersearch/ab',
    idToken,
    formatDefaultPeopleData,
    dispatch
  );
  return people;
}

async function queryDefaultDepartments(dispatch, idToken) {
  const queryParam = '/depts/230027,212000,210307,513000,514400,252000,302200,457500,235500';
  const departments = await queryDefaultData(
    'departments',
    queryParam,
    idToken,
    formatDefaultDepartmentData,
    dispatch
  );
  return departments;
}

async function queryDefaultRoomTypes(dispatch, idToken) {
  const roomtypes = await queryDefaultData(
    'roomtypes',
    '/roomtypes',
    idToken,
    formatDefaultRoomTypeData,
    dispatch
  );
  return roomtypes;
}

export function createId() {
  return Date.now() + parseInt((Math.random()*100), 0);
}

// deal with aws lambda cold start
export const initialAPIPing = (idToken) => {
  searchQuery('/rooms/2130483', idToken);
}

// ref: https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}