import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { actionAuthReset } from './authRedux';
import StatusUpdate from './StatusUpdate';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    zIndex: 99999999999999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    display: 'none !important',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    maxWidth: 345,
    height: 350,
    maxHeight: 360,
    backgroundColor: 'rgba(0, 39, 76, 0.9)',
    padding: '10px 20px',
    '& img:hover': {
      cursor: 'pointer',
    }
  },
  btns: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 'auto',
    '& button': {
      width: '100%',
      marginTop: 10,
    }
  },
  notAuthorized: {
    color: 'red',
    marginTop: 50,
    fontWeight: 'bolder',
    textAlign: 'center'
  },
}));

export default function LoginDialog({ auth }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const status = useSelector(state => state.authReducer.status);
  useEffect(() => {
    // console.log('status:', status, 'auth:', auth);
    if (!auth || !auth.signOut) { return }
    if (status === 'sign-out') {
      auth.signOut();
      dispatch(actionAuthReset());
    } else if (status === 'failed') {
      // display not authorized messaged before page gets refreshed after sign out
      setTimeout(() => { auth.signOut() }, 5000);
    }
  }, [dispatch, auth, status]);

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <MGISLogo />
          <NotAuthorized status={status} auth={auth}/>
        </CardContent>
        <CardActions className={classes.btns}>
          <LoginButton auth={auth} status={status}/>
          <RequestAccessButton />
        </CardActions>
        <CardContent style={{padding: 10}}>
          <StatusUpdate />
        </CardContent>
      </Card>
    </div>
  )
}

function NotAuthorized({ status, auth }) {
  const classes = useStyles();
  const clearLocalStorage = () => {
    window.localStorage.clear();
    auth.signOut();
  }

  if (status && status === 'failed') {
    return (
      <div className={classes.notAuthorized}>
        Not Authorized. Please request access
        <div>
          <Button style={{fontSize: '0.65rem', color: '#93a4b4'}} onClick={clearLocalStorage}>
            Clear Cache
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function MGISLogo() {
  const portalClicked = () => { window.open('https://mgis.med.umich.edu/', '_blank') }

  return (
    <CardMedia
      component="img"
      alt="MGIS"
      image="/MGIS-logo.png"
      title="MGIS"
      onClick={portalClicked}
    />
  );
}

function LoginButton({ auth, status }) {
  const loginClicked = () => { auth.getSession() }

  return (
    <Button
      color="default"
      variant="contained"
      disabled={status === 'failed'}
      onClick={loginClicked}
    >
      Login
    </Button>
  );
}

function RequestAccessButton() {
  const requestAccessClicked = () => { window.open('https://mgis.med.umich.edu/home/index.html', '_blank') }

  return (
    <Button
      style={{color: 'rgba(255, 255, 255, 0.75)', marginLeft: 0}}
      size='small'
      onClick={requestAccessClicked}
    >
      Request Access
    </Button>
  );
}