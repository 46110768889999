import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Filters from './Filters';
import { actionDataGroupUpdated, actionDataSelectFeatureRequested, roomsToRrns, actionDataMaxGroupSizeUpdated } from './dataRedux';
import BuildingFloorsDisplay from './DisplayBuildingFloors';
import BuildingDisplay from './DisplayBuilding';
import RoomDisplay from './DisplayRoom';
import TableTools from './TableTools';
import { actionAppBarFloorsInSearchUpdated } from '../AppBar';
import { Tooltip, Typography } from '@material-ui/core';
import { actionSearchVisibleToggled } from '../Search';

const useDataCardStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column-reverse',
  },
  addSearch: {
    textAlign: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem'
    }
  },
  overlap: {
    display: 'inline-block',
    padding: 2,
    marginLeft: 4,
    border: '2px solid red',
    lineHeight: '1.25',
    fontSize: '0.6rem',
    letterSpacing: '0.01em',
    borderRadius: 4,
  },
  card: {
    width: '100%',
    pointerEvents: 'auto',
    marginBottom: 4,
    '& > .MuiCardActions-root': {
      paddingRight: 0,
    },
  },
  expand: {
    padding: 0,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    padding: 0,
    transform: 'rotate(180deg)',
  },
  verticalDots: {
    padding: 0,
    borderRadius: 0,
    width: 20,
    color: 'rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
    }
  },
  cardActionRoot: {
    padding: 2,
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },
  checkbox: {
    marginRight: 6,
  },
  lastGrid: {
    textAlign: 'end',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}))

export default function SearchResultDisplay({ dataGroups }) {
  const classes = useDataCardStyles();

  return (
    <div className={classes.root}>
      {
        dataGroups.map(data=> {
          return <DataCard key={data.id} data={data}/>
        })
      }
      <AddSearchButton />
    </div>
  );
}

function AddSearchButton() {
  const dispatch = useDispatch();
  const classes = useDataCardStyles();
  const overlapSelection = useSelector(state => state.dataCardReducer.overlapSelection);

  const onClick = () => {
    dispatch(actionDataMaxGroupSizeUpdated(1));
    dispatch(actionSearchVisibleToggled());
  }
  
  return (
    <div className={classes.addSearch}>
      <Tooltip title="Add Search" >
        <IconButton size="small" onClick={onClick}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      {
        !!overlapSelection.length &&
        <Tooltip title={`${overlapSelection.length} overlapping room${overlapSelection.length === 1 ? '' : 's'}`}>
          <Typography variant="overline" className={classes.overlap}>
            {`${overlapSelection.length} Overlap${overlapSelection.length === 1 ? '' : 's'}`}
          </Typography>
        </Tooltip>
      }
    </div>
  );
}

function DataCard({ data }) {
  const classes = useDataCardStyles();
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => { setExpanded(!expanded) }
  const [toolsExpanded, setToolsExpanded] = useState(false);
  const handleToolToggle = () => { setToolsExpanded(!toolsExpanded) }

  return (
    <Card className={classes.card} variant="outlined">
      <DataCardHeader
        data={data}
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        handleToolToggle={handleToolToggle}
      />
      <DataCardBody
        data={data}
        expanded={expanded}
        toolsExpanded={toolsExpanded}
      />
    </Card>
  );
}

function DataCardHeader({ data, expanded, handleExpandClick, handleToolToggle }) {
  const classes = useDataCardStyles();
  const { id, filters, loading, layerVisible, rooms, color } = data;
  const dispatch = useDispatch();
  const onClick = () => { handleExpandClick() }
  const toolsOnClick = () => { handleToolToggle() }

  const checkboxClick = () => {
    if (layerVisible) {
      dispatch(actionDataGroupUpdated({ id, layerVisible: false }));
      dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: data.colorLayerId }));
    } else {
      dispatch(actionDataGroupUpdated({ id, layerVisible: true }));
      dispatch(actionDataSelectFeatureRequested({ type: 'room', data: roomsToRrns(rooms), layerId: data.colorLayerId }));
    }
  }

  let loadingIcon = <IconButton size="small"><CircularProgress size={24}/></IconButton>
  let checkboxIcon = (
                        <Tooltip title="Toggle Highlight">
                          <IconButton size="small" onClick={checkboxClick} className={classes.checkbox} data-testid="search-result-highlight-btn">
                            {layerVisible ?  <CheckBoxIcon data-testid="search-result-highlight-checked"/> : <CheckBoxOutlineBlankIcon data-testid="search-result-highlight-not-checked"/>}
                          </IconButton>
                        </Tooltip>
                      );

  return (
    <React.Fragment>
      <CardActions disableSpacing>
        <Grid container >
          <Grid item xs={1} className={classes.center}>
            { loading ? loadingIcon : checkboxIcon }
          </Grid>
          <Grid item xs={9}>
            <Filters filters={filters} id={id}/>
          </Grid>
          <Grid item xs={2} className={clsx(classes.lastGrid, classes.center)}>
            <Tooltip title="Expand/Collapse">
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={onClick}
                aria-expanded={expanded}
                aria-label="show more"
                size="small"
                style={{
                  border: `2px solid ${color}`,
                  backgroundColor: `${color}75`,
                }}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Options">
              <IconButton
                className={classes.verticalDots}
                style={{ visibility: expanded ? 'visible' : 'hidden' }}
                onClick={toolsOnClick}
                aria-label="show more tools"
                size="small"
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardActions>
    </React.Fragment>
  );
}

function DataCardBody({ data, expanded, toolsExpanded }) {
  const { id, rooms, loading, groupBy, sortBy, sortDirection } = data;
  let dataTable;
  if (groupBy === 'building-floor') {
    dataTable = <BuildingFloorsDisplay id={id} rooms={rooms} sortBy={sortBy} sortDirection={sortDirection}/>;
  } else if (groupBy === 'building-floor-sqft') {
    dataTable = <BuildingDisplay id={id} rooms={rooms} sortBy={sortBy} sortDirection={sortDirection}/>;
  } else if (groupBy === 'room') {
    dataTable = <RoomDisplay id={id} rooms={rooms} sortBy={sortBy} sortDirection={sortDirection}/>;
  } else {
    dataTable = <BuildingFloorsDisplay id={id} rooms={rooms} sortBy={sortBy} sortDirection={sortDirection}/>;
  }

  return (
    <React.Fragment>
      <Collapse in={expanded} timeout="auto">
        <Collapse in={toolsExpanded} timeout="auto">
          <TableTools data={data}/>
        </Collapse>
        { !!rooms.length && dataTable }
        { !loading && !rooms.length && <NoResult data={data}/> }
      </Collapse>
    </React.Fragment>
  );
}

function NoResult({ data }) {
  const classes = useDataCardStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionAppBarFloorsInSearchUpdated([]));
    dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: data.colorLayerId  }));
  }, []);

  return (
    <CardActions disableSpacing classes={{root: classes.cardActionRoot}}>
      <Grid container align="center" justifyContent="center">
        No Result
      </Grid>
    </CardActions>
  );
}