import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import RoomIcon from '@material-ui/icons/Room';
import {
  actionInfoBuildingIndexUpdated,
  actionInfoZoomAndHighlight,
  actionInfoFloorShowAllUpdated,
  actionInfoFloorExpandedUpdated
} from './InfoRedux';
import BuildingActions from './BuildingActions';
import Tooltip from '@material-ui/core/Tooltip';
import { floorSorter } from '../AppBar/Floor';
import { actionAppBarFloorUpdated } from '../AppBar';
import PDFDownloadDialog from './PDFDownloadDialog';
import DownloadDialog from './DownloadDialog';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { trackEvent, INFO } from '../../tracking';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  tabs: {
    '& button.MuiTab-root': {
      minWidth: 'auto !important'
    }
  },
}));

export default function BuildingInfo({ building }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const tabIndex = useSelector(state => state.infoReducer.buildingInfoIndex);
  const handleChange = (event, newValue) => {
    dispatch(actionInfoBuildingIndexUpdated(newValue));
    trackEvent({
      category: INFO,
      action: 'Change Tab',
      label: newValue ? 'Additional' : 'Basic'
    });
  };
  const handleChangeIndex = index => { dispatch(actionInfoBuildingIndexUpdated(index)) };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="map layer tabs"
          className={classes.tabs}
        >
          <Tab label="BASIC" {...a11yProps(0)} data-testid="info-building-basic-tab" />
          <Tab label="ADDITIONAL" {...a11yProps(1)} data-testid="info-building-additional-tab" />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <BasicInfo building={building} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <Additional building={building} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{padding: '16px'}}>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useBasicInfoStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-overline': {
      lineHeight: 1.25,
      letterSpacing: '0.03em'
    }
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '0 !important',
  },
  type: {
    marginLeft: 2,
    fontSize: '0.65rem',
    letterSpacing: '0.01em !important',
    fontWeight: 'bold',
    color: 'rgb(195, 88, 135)',
  },
  typeIcon: {
    color: 'white !important',
    backgroundColor: 'rgb(195, 88, 135)',
    borderRadius: '50%',
    letterSpacing: '0',
    display: 'inline-block',
    width: 18,
    height: 18,
    fontSize: '0.55rem',
    lineHeight: '18px',
    textAlign: 'center',
    marginLeft: 4,
  },
  stack: {
    display: 'inline-block',
    '& .MuiButton-root': {
      lineHeight: 1,
    },
    '& .MuiTypography-overline': {
      lineHeight: 1,
    }
  },
  secondary: {
    color: 'grey',
    fontSize: '0.6rem',
    letterSpacing: '0.02em !important',
  },
  table: {
    marginBottom: 16,
    lineHeight: 1.1,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.025)',
    }
  },
  actionIcon: {
    padding: 2,
    marginRight: 2,
    marginTop: -3,
    fontSize: '1.25rem',
    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem',
    }
  },
  floorRoot: {
    lineHeight: 0,
  },
  floor: {
    fontWeight: 'bold',
    marginRight: 4,
  },
  floorBtn: {
    fontSize: '0.65rem',
    padding: '2px 4px',
    minWidth: 'auto',
    lineHeight: 1,
    color: 'grey',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      marginRight: 4,
      color: '#5a5a5a',
    }
  },
  floorExpand: {
    fontSize: '0.6rem',
    padding: 0,
    minWidth: 'auto',
    lineHeight: 1,
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      color: '#878989',
    }
  },
  floorToggle: {
    padding: 2,
    fontSize: '0.6rem',
    color: 'grey',  
  }
}));

function BasicInfo({ building }) {
  const classes = useBasicInfoStyles();
  const dispatch = useDispatch();

  const {
    bld_descrshort, bldrecnbr,
    bld_descr50,
    bldstreetnbr, bldstreetname, bldcity, bldstate, bldpostal,
    bldtyp_descr,
    bldcampuscd_descr,
  } = building;

  const [bldrecnbrLabel, setBldrecnbrLabel] = useState('Building Record Number');
  const onCopyHandler = () => {
    setBldrecnbrLabel('Building Record Number Copied to Clipboard');
    trackEvent({
      category: INFO,
      action: 'Copy',
      label: 'BRN'
    });
  }
  const zoomTo = () => {
    dispatch(actionInfoZoomAndHighlight(bldrecnbr));
    trackEvent({
      category: INFO,
      action: 'Zoom',
    });
  }
  const trackGoogleMapClickEvent = () => {
    trackEvent({
      category: INFO,
      action: 'Click Google Maps Link'
    });
  }


  const googleMapsUrl = `https://maps.google.com/?q=${bldstreetnbr} ${bldstreetname}, ${bldcity}, ${bldstate} ${bldpostal}`;

  return (
    <div className={classes.root}>
      <div>
        <Tooltip title={bldrecnbrLabel}>
          <CopyToClipboard text={bldrecnbr} onCopy={onCopyHandler}>
            <Typography component="span" variant="overline" className={classes.id} data-testid="info-building-bldrecnbr">
              {bldrecnbr} 
            </Typography>
          </CopyToClipboard>
        </Tooltip>
        <span> </span> {/* fix issue when double clicking bldrecnbr grabs TYP too */} 
        <span className={classes.typeIcon}>TYP</span>
        <Tooltip title="Building type">
          <Typography component="span" variant="overline" className={classes.type} data-testid="info-building-type">
            {bldtyp_descr}
          </Typography>
        </Tooltip>
      </div>
      <div style={{marginTop: '8px'}}>
        <Typography component="span" variant="overline" className={classes.title} data-testid="info-building-name">         
          {bld_descrshort}
          <Tooltip title="Zoom In">
            <IconButton color="primary" onClick={zoomTo} className={classes.actionIcon}><RoomIcon /></IconButton>
          </Tooltip>
        </Typography>
        <div className={classes.stack}>
          <Typography component="div" variant="overline" className={classes.secondary} data-testid="info-building-campus">
            {bldcampuscd_descr}
          </Typography>
          <Typography component="div" variant="overline" className={classes.secondary}>
            <Tooltip title="Open Address in Google Maps">
              <a onClick={trackGoogleMapClickEvent} href={googleMapsUrl} target="_blank" rel="noopener noreferrer" data-testid="info-building-address">
                {bldstreetnbr} {bldstreetname}
              </a>
            </Tooltip>
          </Typography>
        </div>
      </div>
      <Typography
        component="div"
        variant="overline"
        className={classes.secondary}
        style={{marginTop: -4, marginBottom: 12}}
      >
        {bld_descr50}
      </Typography>
      <CompactFloors building={building} />
      <BuildingActions building={building} />
    </div>
  );
}

function CompactFloors({ building }) {
  const classes = useBasicInfoStyles();
  const dispatch = useDispatch();

  const {
    floor_list,
    bldrecnbr,
    bld_descrshort,
  } = building;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeFloor, setActiveFloor] = React.useState(null);

  const handleClick = (floor) => (event) => {
    setActiveFloor(floor);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const zoomToFloor = () => { 
    dispatch(actionInfoZoomAndHighlight(bldrecnbr, activeFloor));
    dispatch(actionAppBarFloorUpdated(activeFloor));
    trackEvent({
      category: INFO,
      action: 'Zoom to Floor',
      label: activeFloor,
    });
    handleClose();
  }

  const [downloadVisible, setDownloadVisible] = useState(false);
  const handleDownloadClick = () => { 
    setDownloadVisible(true);
    handleClose();
  };
  const handleDownloadClose = () => { setDownloadVisible(false) };

  const [pdfDownloadVisible, setPdfDownloadVisible] = useState(false);
  const handlePdfDownloadClick = () => {
    setPdfDownloadVisible(true);
    handleClose();
  };
  const handlePdfDownloadClose = () => { setPdfDownloadVisible(false) };

  const tempFloors = floor_list ? [...new Set(floor_list.split(', '))] : [];
  const floors = tempFloors.sort(floorSorter).reverse();

  return (
    floors.length > 0 ?
    <div className={classes.floorRoot}>
        <Typography component="span" variant="overline" className={classes.floor}>         
          Floor
        </Typography>
        <span>
          {
            floors.map(floor => {
              return (
                <Button
                  key={floor}
                  onClick={handleClick(floor)}
                  size="small"
                  className={classes.floorBtn}
                  style={{color: '#3f51b5'}}>
                  {floor}
                </Button>
              )
            })
          }
        </span>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} disabled={true} >
            <Typography variant="overline" >
              <strong>{`FLOOR ${activeFloor}`}</strong>
            </Typography>            
          </MenuItem>
          <MenuItem onClick={zoomToFloor}>
            <ListItemIcon>
              <RoomIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="overline">Zoom</Typography>            
          </MenuItem>
          <MenuItem onClick={handleDownloadClick}>
            <ListItemIcon>
              <GetAppIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="overline">Data</Typography>            
          </MenuItem>
          <MenuItem onClick={handlePdfDownloadClick}>
            <ListItemIcon>
              <PictureAsPdfIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="overline">Floor Plan</Typography>      
          </MenuItem>
        </Menu>
      <PDFDownloadDialog
        open={pdfDownloadVisible}
        onClose={handlePdfDownloadClose}
        bldrecnbr={bldrecnbr}
        name={bld_descrshort}
        floor={activeFloor}
      />
      <DownloadDialog
        open={downloadVisible}
        onClose={handleDownloadClose}
        bldrecnbr={bldrecnbr}
        floor={activeFloor}
        name={bld_descrshort}
      />
    </div>
    : ""
  );
}

function Additional({ building }) {
  const classes = useBasicInfoStyles();

  const {
    bldgrsssqrft, 
    bldcnstsqrft,
    netsqrft,
    assignedsqrft,
    nonassignedsqrft,
    circsqrft,
    bldphase_descrshort,
    bldownership_descr,
    bldsuit_descrshort,
    bldstreetnbr, bldstreetname, bldcity, bldstate, bldpostal,
  } = building;

  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={classes.table}>
          <Grid item container xs={12} className={classes.tableRow}>
            <Grid item xs={6}>
              <Typography variant="overline">Gross Area</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">{`${numberWithCommas(bldgrsssqrft)} sqft`}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}  className={classes.tableRow}>
            <Grid item xs={6}>
              <Typography variant="overline">Building Construction</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">{`${numberWithCommas(bldcnstsqrft)} sqft`}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.tableRow}>
            <Grid item xs={6}>
              <Typography variant="overline">Net</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">{`${numberWithCommas(netsqrft)} sqft`}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.tableRow}>
            <Grid item xs={6}>
              <Typography variant="overline">Net Assignable</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">{`${numberWithCommas(assignedsqrft)} sqft`}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.tableRow}>
            <Grid item xs={6}>
              <Typography variant="overline">Non-Assignable</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">{`${numberWithCommas(nonassignedsqrft)} sqft`}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.tableRow}>
            <Grid item xs={6}>
              <Typography variant="overline">Circulation</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">{`${numberWithCommas(circsqrft)} sqft`}</Typography>
            </Grid>
          </Grid>
      </Grid>
      <Grid container spacing={0} className={classes.table}>
        <Grid item container xs={12} className={classes.tableRow}>
          <Grid item xs={6}>
            <Typography variant="overline">Phase</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline">{bldphase_descrshort}</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.tableRow}>
          <Grid item xs={6}>
            <Typography variant="overline">Ownership</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline">{bldownership_descr}</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.tableRow}>
          <Grid item xs={6}>
            <Typography variant="overline">Suitability</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline">{bldsuit_descrshort}</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.tableRow}>
          <Grid item xs={6}>
            <Typography variant="overline">Address</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline">{bldstreetnbr} {bldstreetname}, {bldcity}, {bldstate} {bldpostal}</Typography>
          </Grid>
        </Grid>
      </Grid>    
    </div>
  );
}

// @ref: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}